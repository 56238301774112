.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: rgb(166, 165, 165);
  font-family: 'Poppins', sans-serif;
  color:rgb(57, 57, 57);
}

/* New */

.shadow {
  margin: 40px;
  border: 'none';
  /* box-shadow: 0 1px 20px 0 rgba(0 ,÷0 ,0 , .7); */
  /* box-shadow: 0 1px 20px 0 rgba(100 , 100, 100, .75); */
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.8);
}
@media only screen and (max-width: 1300px) {
  .shadow {
    margin: 30px 10px;
  }
}
@media only screen and (min-width: 400px) {
  .shadow {
    margin: 30px 35px;
  }
}
.light {
  background-color: #e7e7e7;
  color: rgba(0, 0, 0, 0.6);
  transition: 0.5s;
}
.dark {
  /* background: rgb(57, 57, 57); */
  background: #081f39;
  color: #fff;
  transition: 0.5s;
}
.footer {
  height: 70px;
  width: 100%;
  display: flex;
  position: fixed;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 0;
  margin: 0;
  background-color: #2a4654;
  transition: 0.5s;
}

/* Flicker */

.switch {
  width: 70px;
  height: 36px;
  border-radius: 18px;
  /* background-color: rgb(39, 38, 38); */
  /* background-color: rgb(119, 118, 118); */
  background-color: rgb(76, 103, 147);
  position: relative;
  margin-right: 16px;
}

.flicker {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  /* background-color: rgb(76, 103, 147); */
  position: absolute;
  top: 3px;
  left: 5px;
  transition: 0.3s;
  box-shadow: 
    inset 4px 4px 4px rgb(255,255,255),
    inset -4px -4px 4px rgb(195 193 198 / 90%);
}

.moon {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  box-shadow: 4px 4px 0 0 rgb(255, 255, 255);
  /* box-shadow: 4px 4px 0 0 rgb(76, 103, 147); */
  position: absolute;
  top: 3px;
  left: 40px;
}

.dark .flicker {
  transform: translateX(30px);
}
.dark .moon {
  display: none;
}
.dark .shadow {
  box-shadow: 0 1px 20px 0 rgba(220 ,220 ,220 , .7);
}
.dark .switch {
  /* background-color: rgb(39, 38, 38); */
  /* background-color: rgb(48, 87, 149); */
  /* background-color: rgb(52, 79, 124); */
  background-color: rgb(70, 90, 124);
}
.dark > * {
 color:rgba(255, 255, 255, 0.75);
}

.dark .footer {
  background-color: #022C43;
  transition: 0.5s;
}
